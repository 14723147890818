* {
  font-family: Roboto;
  font-size: 14px;
}
.modal-contact-us {
  max-width: 50em;
  width: 95% !important;
  z-index: 9999 !important;
  top: 0 !important;
  margin: 5em auto !important;
}
.modal-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 550px;
  background-color: #ffffff;
  border-radius: 30px;
}

.modal-header {
  background-color: #4285f4;
  display: flex;
  justify-content: space-between;
  height: 50px;
}

.modal-title {
  color: white;
  display: flex;
  align-items: flex-start;
  padding: 2px;
  background-color: #4285f4;
  font-family: Roboto;
  font-size: 13px;
}

.close-icon {
  color: white;
  margin: 2px;
}

.modal-content {
  text-align: center;
  padding: 2em;
}

.modal-description {
  color: #344760;
  font-family: Roboto;
  font-size: 10px;
}
