.terms-page {
  max-width: 1100px;
  margin: 0 auto;
  padding: 20px;
  font-family: Roboto;
  text-align: justify;
  padding-bottom: 0;
}
.privacy-heto-section {
  padding-top: 60px;
}
.headText {
  font-size: 46px;
  font-weight: bold;
  color: #1890cd;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.heading-h3 {
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
}

.term-section ul {
  list-style-type: disc; /* Remove default list styles */
  padding: 10px 0 10px 0; /* Remove default padding */
  margin-left: 20px; /* Remove default margins */
}

.term-section ul li {
  font-size: 13px;
  line-height: 1.6;
  font-weight: 600;
  color: black;
  padding: 5px;
  font-family: Roboto;
  text-align: justify;
}

.styled-ul-a {
  list-style-type: lower-alpha; /* Style list items as lowercase alphabetical */
}

.styled-ul-a li {
  margin-bottom: 10px; /* Add some spacing between list items */
}

.navbar {
  padding: 10px;
}

.menu-logo {
  width: 160px;
  margin-right: 10px;
}

.logoContainer {
  display: flex;
  align-items: center;
}

hr {
  border-top: 1px solid #ccc;
  margin: 20px 0;
}

.navbar {
  border: 0;
  max-width: 160%;

  vertical-align: middle;
  display: inline-block;
}

.logoContainer {
  display: flex;
  align-items: center;
}

.term-section {
  flex: 1;
  width: "50%";
  margin-bottom: 20px;
  box-sizing: border-box;
  justify-content: "space-around";
  align-items: "center";
  margin-right: "40px";
  text-align: "justify";
}

.second {
  font-style: italic;
}
.term-section p {
  font-size: 14px;
  line-height: 1.6;
  color: #030303;
  margin: 0; /* Remove default margins */
  font-family: Roboto;
  font-weight: 600;
}
.underline {
  border: none;
  border-top: 1px solid #000000;
  margin-top: 20px; /* Adjust as needed */
  color: #0c0c0c;
}

.hero-hired-wrap {
  margin-bottom: 40px;
  padding: 20px;
}
.footerText {
  font-size: 14px;
  line-height: 1.6;
  color: #030303;
  margin: 0; /* Remove default margins */
  font-family: Roboto;
  font-weight: 600;
}
.footerText a {
  margin: 0 10px;
  color: #000000;
}
.copyright {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3%;
}
.footerText {
  font-size: 14px;
  line-height: 1.6;
  color: #030303;
  margin: 0; /* Remove default margins */
  font-family: Roboto;
  font-weight: 600;
}
