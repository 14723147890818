nav.ps-menu-root {
  background-color: #910c86 !important;
  min-height: 100%;
  display: flex;
  justify-content: center;
}

aside.ps-sidebar-root {
  height: 100%;
}
.menu-logo:hover {
  cursor: pointer;
}

.css-vj11vy.ps-menu-root {
  justify-content: flex-start !important;
}

.css-3hn74v > .ps-menu-button:hover,
.css-eh8qic > .ps-menu-button:hover {
  background-color: black !important;
}

.css-1wvake5 {
  width: 314px !important;
  min-width: 314px !important;
}

.css-1wvake5.ps-collapsed {
  width: 100px !important;
  min-width: 100px !important;
}

.css-b3iwgq {
  color: #fff !important;
  font-size: 13px;
  font-weight: 400;
  margin-left: 25px !important;
  white-space: nowrap;
}

.css-eh8qic > .ps-menu-button {
  color: #fff !important;
  font-size: 13px !important;
  padding-left: 20px !important;
  display: flex !important;
  height: 42px !important;
}

.css-3hn74v > .ps-menu-button {
  color: #fff !important;
  font-size: 13px !important;
  padding-right: 0 !important;
  padding-left: 20px !important;
  height: 42px !important;
  display: flex !important;
}

.css-1qhe6ly {
  display: none !important;
}

.css-12w9als {
  flex-grow: 0 !important;
  overflow: visible !important;
  display: flex;
  align-items: center;
}

.css-16jkw2k > .ps-menu-button {
  padding-left: 20px !important;
  height: 42px !important;
}

.css-16jkw2k > .ps-menu-button:hover {
  background-color: black !important;
}

.menu-collapsed-btn {
  background: #eee5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  /* left: 205px; */
  top: 20px;
  left: 48px;
  transition-duration: 0.3s;
  box-shadow: 9px 1px 26px 2px rgb(165 165 165);
}

.menu-expanded-btn {
  background: #eee5e5;
  display: flex;
  align-items: center;
  justify-content: center;
  transition-duration: 0.3s;
  box-shadow: 9px 1px 26px 2px rgb(165 165 165);
}

.ps-submenu-content.ps-active.css-1l5fxv3 .css-ewdv3l .ps-menu-button {
  width: 100% !important;
}

.ps-menuitem-root.ps-active.css-eh8qic {
  background-color: black;
  width: 100% !important;
}

.ps-menuitem-root a {
  transition-duration: 0.2s;
  width: 100%;
}

/* .ps-submenu-content.css-1l5fxv3 */

.ps-sidebar-root.ps-collapsed.css-1wvake5 .ps-menuitem-root a {
  width: 100px !important;
}
.ps-sidebar-root .ps-menuitem-root a.ps-menu-button {
  height: 48px !important;
}

.ps-sidebar-root.ps-collapsed .ps-submenu-root .ps-menuitem-root a {
  width: 100% !important;
}

.ps-sidebar-root.ps-collapsed .ps-menuitem-root .ps-submenu-content a {
  width: 100% !important;
}

.my-sidebar {
  position: fixed;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 20;
  background: #910c86;
}

.ps-menu-label img {
  width: 19px !important;
}

.main-menu-div {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
}

.ps-menuitem-root.ps-submenu-root .ps-menu-button:hover {
  background-color: transparent !important;
}

.css-ewdv3l {
  display: flex;
  flex-direction: column;
  background-color: #910c86;
}

.css-1l5fxv3 {
  width: auto !important;
}

.css-16jesut > .ps-menu-button {
  display: auto !important;
  padding-left: 19px !important;
  padding-left: 10px;
  height: auto !important;
}

.ps-submenu-content.css-1l5fxv3 {
  transform: translate3d(60.3333px, 413.333px, 0);
}

.view-menu {
  display: none;
}

.view-menu:hover {
  display: block;
}

.css-1097eso {
  visibility: hidden;
}

.ps-menu-label.css-12w9als {
  color: #fff !important;
  font-size: 13px;
}

.setup {
  margin-right: 15px !important;
}

.ps-submenu-content.css-1l5fxv3 .ps-menu-suffix.css-1qhe6ly {
  display: block !important;
  opacity: 1;
  font-size: 13px;
  color: #fff;
}

.css-1cuxlhl {
  margin-left: 111px;
  color: #fff;
}

.css-dip3t8 {
  background-color: transparent !important;
}
.css-1t8x7v1 > .ps-menu-button:hover {
  background-color: #730c68 !important;
}
.css-1t8x7v1 > .ps-menu-button {
  padding-left: 40px !important;
}
.css-ewdv3l {
  width: 100%;
}
