.frameChild,
.loginPageChild {
  position: relative;
  background-color: var(--secondary-white);
  display: none;
  max-width: 100%;
}
.loginPageChild {
  width: 1440px;
  height: 900px;
}
.frameChild {
  align-self: stretch;
  border-radius: var(--br-xl);
  box-shadow: 0 12px 24px rgba(56, 104, 233, 0.16);
  width: 1008px;
}
.frameWrapper,
.rectangleParent {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.frameWrapper {
  width: 312px;
  flex-direction: column;
  padding: var(--padding-21xl) 0 0;
  box-sizing: border-box;
  min-width: 312px;
}
.rectangleParent {
  border-radius: var(--br-xl);
  background-color: #fff;
  box-shadow: 0 12px 24px rgba(56, 104, 233, 0.16);
  width: 1008px;
  flex-direction: row;
  gap: 52px;
  max-width: 100%;
  z-index: 1;
}
.frameItem,
.termsOfUse {
  position: relative;
  z-index: 1;
}
.termsOfUse {
  flex: 1;
  line-height: 16px;
  white-space: nowrap;
}
.frameItem {
  background-color: var(--color-gainsboro-100);
  width: 1px;
  height: 20px;
}
.privacyPolicy {
  flex: 1;
  line-height: 16px;
  white-space: nowrap;
}
.contactUs,
.frameInner,
.privacyPolicy {
  position: relative;
  z-index: 1;
}
.frameInner {
  background-color: var(--color-gainsboro-100);
  width: 1px;
  height: 20px;
}
.contactUs {
  line-height: 16px;
  white-space: nowrap;
}
.termsOfUseParent {
  width: 304px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
}
.copyrightStafflineproInc {
  position: relative;
  line-height: 16px;
  white-space: nowrap;
  z-index: 1;
}

.loginPage {
  display: flex;
  align-items: center;
}
.frameParent {
  width: 592px;
  height: 634px;
  flex-shrink: 0;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
  text-align: left;
  font-size: var(--vfx-body-text-1-regular-size);
  color: var(--primary-grey);
  font-family: var(--vfx-body-text-1-regular);
}
.loginPage {
  position: relative;
  background-color: var(--secondary-white);
  width: 100%;
  overflow: hidden;
  flex-direction: column;
  justify-content: flex-start;
  padding: 133px var(--padding-xl) 24px;
  box-sizing: border-box;
  gap: 89px;
  letter-spacing: normal;
}
@media screen and (max-width: 950px) {
  .frameWrapper {
    flex: 1;
  }
  .rectangleParent {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 700px) {
  .rectangleParent {
    gap: 26px;
  }
  .copyrightStafflineproInc {
    display: none;
  }
  .frameParent {
    width: 898px;
  }
  .loginPage {
    gap: 44px;
  }
}
@media screen and (max-width: 450px) {
  .loginPage {
    gap: 22px;
  }
}

.btn-primary {
  color: #fff;
  background-color: #910c86 !important;
  border-color: #910c86;
}
.btn-primary:hover {
  color: #fff;
  background-color: #eb2f96 !important;
  border-color: #0062cc;
}
.btn-primary:focus,
.btn-primary.focus {
  color: #fff;
  background-color: #eb2f96 !important;
  border-color: #eb2f96;
  box-shadow: 0 0 0 0.2rem rgba(145, 11, 134, 0.5);
}
.btn-primary.disabled,
.btn-primary:disabled {
  color: #fff;
  background-color: #910c86;
  border-color: #910c86;
}
.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #910c86;
  border-color: #910c86;
}
.btn-primary:not(:disabled):not(.disabled):active:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(145, 11, 134, 0.5);
}

.non-underline {
  text-decoration: none !important;
  color: #910c86 !important;
  font-weight: bold !important;
}
.underline {
  color: #353535 !important;
  font-weight: bold !important;
  text-decoration: underline !important;
}
