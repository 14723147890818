.monitor-progress {
  background-color: #e8f4ff;
  font-size: 18px !important;
  padding-top: 10px;
  padding-bottom: 10px;
  text-align: center;
  font-weight: 600 !important;
}
.monitor-progress a {
  margin-right: 8px;
  color: #910c86;
  font-weight: bolder;
}

.monitor-progress .arrow {
  background: #bcd7f0;
  border-radius: 100%;
}
.categories {
  padding: 20px;
  margin-bottom: 10px;
  margin-left: 10px;
  padding-bottom: 0;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: none;
  border: solid 1px #d7d4d4;
  background-color: #f9fafee0;
}
.categories:hover {
  background-color: #fff;
  cursor: pointer;
}
.categories.MuiGrid-grid-xs-3 {
  max-width: calc(25% - 20px);
  margin-left: 20px;
  margin-right: 0;
  margin-bottom: 20px;
}
.categories.MuiGrid-grid-xs-4 {
  max-width: calc(33.333333% - 20px) !important;
  margin-left: 20px;
  margin-right: 0;
  margin-bottom: 20px;
}
.categories .MuiPaper-elevation.MuiPaper-rounded {
  box-shadow: none;
  background: none;
}
.categories .css-1ke6s24-MuiPaper-root {
  box-shadow: none;
  border-radius: 0%;
  background-color: transparent;
  display: flex;
  align-items: center;
  flex-direction: row;
  align-items: flex-end;
}
.categories h2 {
  margin-bottom: 12px;
}
.categories svg {
  color: #910c86;
}
.alert-dialog-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.upload-dialog .MuiFormControlLabel-label {
  font-size: 14px;
}

.upload-dialog .css-1t1j96h-MuiPaper-root-MuiDialog-paper {
  max-width: 1100px;
  
}

.upload-dialog .MuiFormControlLabel-root.MuiFormControlLabel-labelPlacementEnd {
  margin-right: 5px;
  margin-bottom: 0;
}

.upload-dialog
  .MuiButtonBase-root.MuiRadio-root.MuiRadio-colorPrimary.PrivateSwitchBase-root {
  padding-bottom: 0px;
  padding-top: 0px;
}
.csv-render {
  padding: 20px;
}
.csv-render button {
  text-transform: capitalize;
}
.MuiPaper-outlined[aria-hidden="true"] {
  display: none;
}
.cursor-pointer {
  cursor: pointer;
}
.deleteContent {
  padding: 10px;
  margin-top: 10px;
}
.css-1rwt2y5-MuiButtonBase-root-MuiButton-root:hover {
  border-width: 0 !important;
}
button:focus {
  outline: 0 !important;
}
.css-uhb5lp {
  max-width: 1100px !important;
}
.MuiButton-outlined:hover,
.MuiButton-outlined:active,
.MuiButton-outlined:focus {
  border: 1px solid rgba(25, 118, 210, 0.5) !important;
}
.MuiButton-outlined {
  text-transform: initial !important;
}
