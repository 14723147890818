body button.registercomplaint {
  background: #910c86;
  color: #fff;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  padding: 10px 25px;
  line-height: 25px;
  text-align: center;
  border: solid 4px #fff;
  border-radius: 25px;
  margin-right: 25px;
  font-size: 1rem;
}
body button.registercomplaint:hover {
  background: #730c6a;
}
body button.adminlogin {
  background: #730c6a;
  color: #fff;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  padding: 10px 25px;
  line-height: 25px;
  text-align: center;
  border: solid 4px #fff;
  border-radius: 25px;
  font-size: 1rem;
}
body button.adminlogin:hover {
  background: #910c86;
}
